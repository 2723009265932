import React from 'react'
import { Link } from 'react-router-dom'

import PrimaryPinkButton from './primary-pink-button'
import './header.css'

const Header = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div data-role="Header" className="header-header">
      <nav className="header-nav" style={{
        position: "relative",
        borderBottomLeftRadius: isOpen ? '0px' : "40px",
        borderBottomRightRadius: isOpen ? '0px' : "40px",
      }}>
        <div className="header-container">
          <div className='header-menu' style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <img src="/logo.png" alt="logo" style={{
              width: '32px',
              height: '32px',
              marginRight: '10px'
            }} />
            <Link to="/" className="header-navlink Large">
              Chamunda Brokers
            </Link>
          </div>
          <div className="header-menu">
            <a href="/#home" className="header-navlink1 Large">
              Home
            </a>
            <a href="#our-products" className="header-navlink1 Large">
              Products
            </a>
            <a href="#about-us" className="header-navlink1 Large">
              About Us
            </a>
            <a href="#why-us" className="header-navlink1 Large">
              Why Us ?
            </a>
          </div>
          <div data-role="BurgerMenu" className="header-burger-menu" onClick={() => {
            setIsOpen(!isOpen)
          }}>
            {isOpen ? <svg viewBox="0 0 1024 1024" className="header-icon2">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg> : <svg viewBox="0 0 1024 1024" className="header-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>}
          </div>
        </div>
      </nav>
      {isOpen && <div data-role="MobileMenu" style={{
        maxWidth: "90%",
        display: 'flex',
        position: "absolute",
        top: '70px',
        width: '90%',
        height: 'fit-content',
        zIndex: '100',
        backgroundColor: 'white',
        minHeight: "20vh",
        padding: "10px",
        borderBottomLeftRadius: '40px',
        borderBottomRightRadius: '40px',
        boxShadow: "0 .25rem .375rem -.0625rem hsla(0, 0%, 8%, .12), 0 .125rem .25rem -.0625rem hsla(0, 0%, 8%, .07)",
        backdropFilter: "saturate(200%) blur(30px)",
        backgroundColor: "hsla(0, 0%, 100%, .8)",
        transition: "all .3s ease-in-out",
      }}>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '15px',
          gap: '10px'
        }}>
          <a href="#home" className="header-navlink2 Large" onClick={() => setIsOpen(false)}>Home</a>
          <a href="#our-products" className="header-navlink2 Large" onClick={() => setIsOpen(false)}>Our Products</a>
          <a href="#about-us" className="header-navlink2 Large" onClick={() => setIsOpen(false)}> About Us</a>
          <a href="#why-us" className="header-navlink2 Large" onClick={() => setIsOpen(false)}> Why Us ?</a>
        </div>
      </div>}

      {/* <div data-role="MobileMenu" className="header-mobile-menu">
        <div className="header-top">
          <Link to="/" className="header-navlink2 Large">
            Chamunda Brokers
          </Link>
          <div data-role="CloseMobileMenu" className="header-close-menu" onClick={() => {
            document.querySelector('[data-role="MobileMenu"]').style.display = 'none'
          }}>
            <svg viewBox="0 0 1024 1024" className="header-icon2">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        <div className="header-mid">
          <div className="header-menu1">
            <a href="#home" className="header-navlink3 Large" onClick={() => {
              document.querySelector('[data-role="MobileMenu"]').style.display = 'none'
            }}>
              Home
            </a>
            <a href="#our-products" className="header-navlink3 Large" onClick={() => {
              document.querySelector('[data-role="MobileMenu"]').style.display = 'none'
            }}>
              Products
            </a>
            <a href="#about-us" className="header-navlink3 Large" onClick={() => {
              document.querySelector('[data-role="MobileMenu"]').style.display = 'none'
            }}>
              About Us
            </a>
            <a href="#why-us" className="header-navlink3 Large" onClick={() => {
              document.querySelector('[data-role="MobileMenu"]').style.display = 'none'
            }}>
              Why Us ?
            </a>
          </div>
        </div>
        <div className="header-bot">
          <PrimaryPinkButton button="buy now"></PrimaryPinkButton>
        </div>
      </div> */}
    </div>
  )
}

export default Header
