import React from 'react'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className="footer-footer">
      <div className="footer-container">
        <div className="footer-container2">
          <div className="footer-container3">
            <a href='#home' className="footer-text2 Large">Home</a>
            <a href='#about-us' className="footer-text3 Large">About Us</a>
            <a href='#our-products' className="footer-text3 Large">Our Products</a>
            <a href='#why-us' className="footer-text3 Large">Why Us ?</a>
            {/* <span className="footer-text4 Large">Contact Us</span> */}
          </div>
        </div>
        <div className="footer-container1">
          <span className="footer-text">Main Office</span>
          <span className="footer-text Large ">Chamunda Broker's (Ahmedabad) <br />
            Cotton Fiber & Seeds <br />
          </span>
          <span className="footer-text3 Large">
            807/B Synergy Tower, Corporate Rd, <br /> Prahlad Nagar, Ahmedabad, <br /> Gujarat - 380015.
            <br />(+91) 98252 50257
            <br />(+91) 94285 89775
          </span>
          <br />
          <span className="footer-text Large">Chamunda Broker's (Dhasa) <br />
            Cotton Fiber & Seeds <br />
          </span>
          <span className="footer-text3 Large">
            Sarathi-1 Complex, Dhasa-Rajkot Highway,  <br /> Dhasa, <br /> Gujarat - 364730.
            <br />(+91) 98252 50257
            <br />(+91) 88661 48508
          </span>
          <br />
        </div>
        <div className="footer-container2">
          <div className="footer-container3">
            <span className="footer-text">Other Offices</span>
            <span className="footer-text Large">Chamunda Broker's (Mahuva) <br />
            </span>
            <span className="footer-text3 Large">
              66, Muni. Shopping Centre, Station Road, <br /> Mahuva - 364290.
              <br />(+91) 94283 42347
            </span>
            <br />
            <span className="footer-text Large">Chamunda Trading Co. (Mahuva) <br />
              Cotton Seeds <br />
            </span>
            <span className="footer-text3 Large">
              25, Wireless Line, APMC, <br /> Mahuva - 364290.
              <br />(+91) 99987 99688
            </span>
            <br />
          </div>
        </div>

      </div>
      <span style={{
        marginTop: "-25px",
        marginBottom: "20px"
      }}>Copyright © 2023 Chamunda Brokers.</span>
      <img alt="image" src="/waves-white.svg" className="footer-image" />
    </footer>
  )
}

export default Footer
